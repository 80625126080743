<template>
  <div class="tabber">
    <router-view v-slot="{ Component }"><transition name="router-fade" mode="out-in"><component :is="Component" /></transition></router-view>
    <van-tabbar z-index="1000" :border="false" fixed v-model="active" @change="onChange" safe-area-inset-bottom>
      <van-tabbar-item replace v-for="item in tabBarArr" :key="item.id">
        <template #icon="props"><img :src="props.active ? item.selected : item.normal" /></template> {{ item.name }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, defineComponent, onMounted } from "vue";
import cookies from 'vue-cookies';
export default defineComponent({
  setup() {
    const router = useRouter();
    const active = ref(0);
    // 在 Dom 加载完触发
    onMounted(() => {
      let index = tabBarArr.findIndex((item) => item.routeName == router.currentRoute.value.name);
      active.value = index;
    });

    let tabBarArr = [];
    let min_id = cookies.get("minId");
    console.log("min_id:", min_id);
    if (min_id > 0) {
      tabBarArr = [
        { id: 1, name: "首页", path: "/", routeName: "/", selected: require("@/assets/img/a01.png"), normal: require("@/assets/img/a02.png") },
        { id: 2, name: "商品库", path: "/house", routeName: "house", selected: require("@/assets/img/a05.png"), normal: require("@/assets/img/a06.png") },
        { id: 3, name: "我的", path: "/mine", routeName: "mine", selected: require("@/assets/img/a07.png"), normal: require("@/assets/img/a08.png") }
      ] 
    } else {
      tabBarArr = [
        { id: 1, name: "首页", path: "/", routeName: "/", selected: require("@/assets/img/a01.png"), normal: require("@/assets/img/a02.png") },
        { id: 2, name: "市场", path: "/good", routeName: "good", selected: require("@/assets/img/a03.png"), normal: require("@/assets/img/a04.png") },
        { id: 3, name: "藏品库", path: "/house", routeName: "house", selected: require("@/assets/img/a05.png"), normal: require("@/assets/img/a06.png") },
        { id: 4, name: "我的", path: "/mine", routeName: "mine", selected: require("@/assets/img/a07.png"), normal: require("@/assets/img/a08.png") }
      ] 
    };
    
    const onChange = (index) => {
      router.replace(tabBarArr[index].path);
    };
    return {
      active,
      tabBarArr,
      onChange,
    };
  },
});
</script>

<style lang="scss" scoped>
// 判断iphoneX 将 footerBox 的 padding-bottom 填充到最底部
@supports (bottom: env(safe-area-inset-bottom)) { .footBox { padding-bottom: env(safe-area-inset-bottom); } }
@supports (bottom: constant(safe-area-inset-bottom)) { .footBox { padding-bottom: constant(safe-area-inset-bottom);} }
// vant tabber
:deep(.van-tabbar) {width: 100vw; height: 14vw;  .van-tabbar-item__icon > img {width: 5vw; height: 5vw;} }
</style>
